import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import MobileMenu from "../components/MobileMenu";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import FAQ from "../components/FAQ";
import Footer from "../components/Footer";

const Faq = ({ data, pageContext }) => {
  return (
    <Layout pageTitle="GYIK | MyFoodProtocol">
      <Header inner={true} btnClass="main-nav__btn-two" />
      <MobileMenu inner={true} />
      <PageHeader
        pageHeading={
          pageContext.title ? pageContext.title : "Gyakran Ismételt Kérdések"
        }
        breadcrumb={[{ url: "/gyik", title: "GYIK" }]}
      />

      <div className="my-5">
        <FAQ data={data}></FAQ>
      </div>
      <Footer />
    </Layout>
  );
};

export default Faq;

export const query = graphql`
  query FaqQuery {
    allStrapiFaq(
      filter: { publishedAt: { ne: "null" } }
      sort: { order: ASC, fields: OrderNo }
    ) {
      nodes {
        Title
        Category
        Description {
          data {
            Description
          }
        }
      }
    }
  }
`;
