import React from "react";
import { Container } from "react-bootstrap";
import BlockTitle from "./BlockTitle";
import AccordionItem from "./AccordionItem";
import parse from "html-react-parser";

const FAQ = ({ data }) => {
  // console.log("FAQ", data);
  const faqCategories = [];
  data.allStrapiFaq.nodes.map((faq) => {
    if (!faqCategories.includes(faq.Category)) {
      faqCategories.push(faq.Category);
    }
  });
  return (
    <section className="faq-one">
      <Container>
        <BlockTitle
          textAlign="center"
          paraText="Gyakran Ismételt Kérdések"
          titleText=""
        />
        {faqCategories.map((category, index) => (
          <React.Fragment key={index}>
            <BlockTitle textAlign="center" paraText="" titleText={category} />
            <div className="accrodion-grp faq-accrodion">
              {data.allStrapiFaq.nodes
                .filter((item) => item.Category === category)
                .map((faq, catIndex) => (
                  <AccordionItem
                    key={`${index}-${catIndex}`}
                    title={faq.Title}
                    content={parse(faq.Description.data.Description)}
                    status={index === 0 && catIndex === 0 ? true : false}
                  />
                ))}
            </div>
          </React.Fragment>
        ))}
      </Container>
    </section>
  );
};

export default FAQ;
